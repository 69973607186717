import './Blog.css';

// imageUrl, title, description
const Blog = (props) => {
    return (
        <a className="Blog" href={props.redirectUrl} target="_blank" rel="noreferrer">
            <div className="image">
                <img src={props.imageUrl} alt="Blog"/>
            </div>
            <div className="title">
                {props.title}
            </div>
        </a>
    );
};

export default Blog;
