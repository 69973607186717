import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const primary = {
    main: '#5e3418',
    light: '#fdf1ec',
    dark: '#FFBE98',
    contrastText: '#5e3418',
};

const theme = createTheme({
    palette: {
        primary,
    },
});

// export default theme;

export default function ShivaniTheme({ children }) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
