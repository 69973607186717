import "./ThreeColumnContainer.css";

const ThreeColumnContainer = ({children}) => {
    return (
        <div className="ThreeColumnContainer">
            {children}
        </div>
    );
};

export default ThreeColumnContainer;
