import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ShivaniTheme from '../../../theme';

export default function CompactSwitcher(props) {
    const handleChange = (_event, newAlignment) => {
        props.setAlignment(newAlignment);
    };

    return (
        <ShivaniTheme>
            <ToggleButtonGroup
                color="primary"
                value={props.alignment}
                exclusive
                onChange={handleChange}
                aria-label="Version"
                size="large"
            >
                <ToggleButton value="short">TL;DR</ToggleButton>
                <ToggleButton value="long">The Long Version</ToggleButton>
            </ToggleButtonGroup>
        </ShivaniTheme>
    );
}
