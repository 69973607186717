import './TitleBox.css';

// title, description
const TitleBox = (props) => {
    return (
        <div className="TitleBox">
            <h1 dangerouslySetInnerHTML={{__html: props.title}}/>
            <div className="description" dangerouslySetInnerHTML={{__html: props.description}}/>
        </div>
    );
};

export default TitleBox;
