import * as React from 'react';
import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import image1 from '../../images/aistudio1.jpeg';
import image2 from '../../images/aistudio11.jpg';
import TopNav from '../../toolbar/TopNav';
import imageConfigureModelForm from '../../images/aistudio/ConfigureModelForm.png';
import imageDefaultJpg from '../../images/aistudio/Default.jpg';
import imageDefaultPng from '../../images/aistudio/Default.png';
import imageEditCandidateSet from '../../images/aistudio/EditCandidateSet.png';
import imageFocusView from '../../images/aistudio/FocusView.jpg';
import imageForm from '../../images/aistudio/Form.jpg';
import imageListDefault from '../../images/aistudio/ListDefault.png';
import imageModalAnitSetAddedOptional from '../../images/aistudio/ModalAnitSetAddedOptional.jpg';
import imageModelValidationPreview from '../../images/aistudio/ModelValidationPreview.png';
import imagePublishModelForm from '../../images/aistudio/PublishModelForm.png';
import imageRuleDialog from '../../images/aistudio/RuleDialog.jpg';
import imageSelectMachineLearningTemplate from '../../images/aistudio/SelectMachineLearningTemplate.png';
import imageSnackbar from '../../images/aistudio/Snackbar.jpg';
import imageDefaultCandidateSet from '../../images/aistudio/DefaultCandidateSet.png';
import imageListDefaultValidation from '../../images/aistudio/ListDefaultValidation.png';
import imageAiStudioBanner3 from '../../images/aistudio/aibanner3.png';
import imagePersona1 from '../../images/aistudio/persona1.png';
import imagePersona2 from '../../images/aistudio/persona2.png';
import imagePersona3 from '../../images/aistudio/persona3.png';
import imageSketch1 from '../../images/aistudio/ai_sketch1.jpg';
import imageSketch2 from '../../images/aistudio/ai_sketch2.jpg';
import imageSketch3 from '../../images/aistudio/ai_sketch3.jpg';
import imageSketch4 from '../../images/aistudio/ai_sketch4.jpg';
import imageSketch5 from '../../images/aistudio/ai_sketch5.jpg';
import Footer from "../../toolbar/Footer";
import './AIStudio.css';
import CompactSwitcher from "./components/CompactSwitcher";
import HalfWidthWrapper from './components/HalfWidthWrapper';


const AIStudio = (props) => {
    const [alignment, setAlignment] = React.useState('short');

    return (
        <div className="mainBox">
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="aiStudio project">
                <div className="project-inner">
                    <ImageSubsection
                        title="AI Studio"
                        banner={true}
                        images={[
                            { image: imageAiStudioBanner3, width: '60rem', height: '35rem' },
                        ]}
                        description='A no-code / low-code Augmented Intelligence Studio to truly democratise building and training ML models<br/><br/><span style="opacity: 0.6">ROLE:</span> Lead Designer<br/><br/><a href="https://www.youtube.com/embed/9cyYtD3eZmA" style="color: black" target="_blank" rel="noreferrer noopener">Watch on YouTube</a>'
                    />
                    <div className="alignmentWrapper">
                        <CompactSwitcher alignment={alignment} setAlignment={setAlignment}/>
                    </div>


                    {alignment === 'short' &&
                        <>
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    title="Introduction"
                                    description="Squirro uses Augmented Intelligence to extract insights from user's data. AI Studio is envisioned to  be the workspace for building and training text classification models and integrating existing technologies in the workflow."
                                />
                                <ProblemStatement
                                    title="Problem Statement"
                                    description="Design a standardized  and easy-to-use no-code/low-code user interface for the AI Studio and ML tools to build and train models, and streamline the user workflow by providing a common framework for integrating various technologies used in the Studio."
                                />
                            </HalfWidthWrapper>
                            <ImageSubsection
                                title="Users"
                                noBackground={true}
                                images={[
                                    { image: imagePersona1 },
                                    { image: imagePersona2 },
                                    { image: imagePersona3 },
                                ]}
                                description=""
                            />
                            <HalfWidthWrapper>
                                <Solution
                                    title="Challenges"
                                    description={[
                                        'No consensus on the end-to-end process of building a model.',
                                        'Highly abstract building blocks.',
                                        'Gap between the existing annotation tools and a holistic solution integrated inside a platform.',
                                    ]}
                                />
                                <ProblemStatement
                                    title="Process Flow Workshop"
                                    description="After the discovery phase, I organized a process flow workshop with the engineers and drafted a model-building process flowchart for the MVP version of the product."
                                />
                            </HalfWidthWrapper>
                            <ImageSubsection
                                title=""
                                images={[{ image: image1, long: true }]}
                                description=""
                            />
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    title="Design Strategy"
                                    description="In our customer interactions, we discovered that while building a model, different user groups are involved at various stages and in different roles. This complex flow led us to adopt the assembly line approach proposed by The Toyota Way. Complex systems can be designed like assembly lines, where actions are repeatable, and the building blocks are laid out in a one-piece flow operational fashion."
                                />
                            </HalfWidthWrapper>
                            <ImageSubsection
                                title="Concept Sketches"
                                images={[
                                    { image: imageSketch1 },
                                    { image: imageSketch2 },
                                    { image: imageSketch3 },
                                    { image: imageSketch4 },
                                    { image: imageSketch5 },
                                ]}
                                description=""
                            />
                            <ImageSubsection
                                title="Final Designs"
                                images={[
                                    { image: image2 },
                                    { image: imageDefaultPng },
                                    { image: imageRuleDialog },
                                    { image: imageDefaultJpg },
                                    { image: imageSelectMachineLearningTemplate },
                                    { image: imageConfigureModelForm },
                                    { image: imagePublishModelForm },
                                    { image: imageModelValidationPreview },
                                    { image: imageModalAnitSetAddedOptional },
                                ]}
                                description=""
                            />
                            <HalfWidthWrapper>
                                <Solution
                                    title="Design Details"
                                    description={[
                                        'Built on Figma using Google Material Design components.',
                                        'A custom navigation pattern where on selecting a tab, the related breadcrumbs replace the tab navigation. FAB is used to exit the step and return to the home page.',
                                    ]}
                                />
                            </HalfWidthWrapper>
                        </>
                    }

                    {alignment === 'long' &&
                        <>
                            <TitleBox
                                title=""
                                description="<a href='https://squirro.com' target='_blank' rel='noreferrer'>Squirro</a>’s primary business is creating user insights using Squirro's Insights Engine platform. For a long time, the insights delivered were purely semantic search and concept search results. As the Machine Learning service was developed to detect catalysts based on text classification models, there was a need to design a standardized user interface to build, train, and deploy ML models. The AI Studio would also be the playground for exploring different ML technologies like sentiment analysis, topic modeling, and known entity extraction. Therefore, the designs should facilitate the explorative approach of model building. The ultimate desired outcome is creating user-consumable insights by possibly linking various models together."
                            />
                            <div className="iframe-wrapper">
                                <iframe src='https://www.youtube.com/embed/9cyYtD3eZmA'
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    title='video'
                                />
                            </div>
                            <ProblemStatement
                                title="Problem Statement"
                                description="The Squirro AI Studio shall be a core component of Squirro’s Insights Engine platform. It shall create a common framework through which we can ship new models to our clients and empower Business Analysts to collect training data, use it to tune a defined model, and deploy it into production. This should be possible using a no-code / low-code user interface."
                            />
                            <Solution
                                title="Users"
                                description={[
                                    'Power users can select fully pre-trained models from the pre-existing library accessible from the marketplace and deploy it to extracts known entities or topics and highlight related sentences in a document.',
                                    'Business Analysts can select a model from the model library and provide pre-defined ontology, tuning parameters and add training and validation data.',
                                    'Developers can upload their own models into the Insights Library. These models can then be used by the Squirro Business Analyst or power users.',
                                ]}
                            />
                            <Solution
                                title="Challenges"
                                description={[
                                    'At the start of the project, it was painfully apparent that every engineer had different ideas about the end-to-end process of building a model.',
                                    'While the vision was clear, the building blocks were highly abstract.',
                                    'During the research phase, we studied many annotation tools and their designs. However, we could not find a good example of a model builder integrated inside a platform that allows the creation of training data or even the deployment of models.',
                                ]}
                            />
                            <ImageSubsection
                                title="Process Flow Workshop"
                                images={[{ image: image1, long: true }]}
                                description="Not finding a comparable product to study was a blessing in disguise as we stayed uninfluenced and set out to build the best product in this category.
                                The first step was to have a common understanding with the engineers, who were the main drivers of this technical tool. I organized a process flow workshop with our CTO, and we came up with a preliminary flowchart that was used as a foundation for the technical architecture."
                            />
                            <ImageSubsection
                                title="Design Strategy Inspired by The Toyota Way"
                                images={[
                                    { image: image2 },
                                    { image: imageDefaultCandidateSet },
                                ]}
                                description="After many interactions with our customers, we discovered that different user groups will be involved in building the same models but in different ways and stages. For an organization, a developer may create a model, a data scientist may train it, and a business analyst may validate and publish it. The same user could perform all these actions. This complex flow led us toward the assembly line approach proposed by The Toyota Way. Complex systems can be thought of as an assembly line where the actions are repeatable, and the building blocks are laid out in a one-piece flow operational fashion so the product flows through value-adding processes without interruption, where a value-adding process is the information transformation. This fast and flexible process allows altering individual parts while also managing the lifecycle of the models."
                            />
                            <ImageSubsection
                                title=""
                                images={[
                                    { image: imageEditCandidateSet, width: '60rem', height: '35rem' },
                                ]}
                                description="We designed the AI Studio in five components: Candidate Sets, Ground Truth, Model, Validation, and Publish. The starting point of the model creation is not the model's selection but rather the model's utility. Per the customer's business requirements, a Business Analyst would prepare a list of industry-specific signals or concepts to be detected in their data. They would then create a Candidate Set, a sub-set of all documents in Squirro related to a particular signal, for example, M&A, which can be defined by a search query, concept search, or proximity filter."
                            />
                            <ImageSubsection
                                title=""
                                images={[
                                    { image: imageDefaultPng, width: '60rem', height: '35rem' },
                                    { image: imageModalAnitSetAddedOptional, width: '60rem', height: '35rem' },
                                    { image: imageSnackbar, width: '60rem', height: '35rem' },
                                    { image: imageForm, width: '60rem', height: '35rem' },
                                    { image: imageRuleDialog, width: '60rem', height: '35rem' },
                                    { image: imageFocusView, width: '60rem', height: '35rem' },
                                    { image: imageDefaultJpg, width: '60rem', height: '35rem' },
                                ]}
                                description="Using one or more Candidate Sets, the Ground Truth is created. Ground Truth is the data set used to train an ML model and represents the truth against which the model gets optimized. Here, data is labeled using Squirro's search and tagging capabilities."
                            />
                            <ImageSubsection
                                title=""
                                images={[
                                    { image: imageSelectMachineLearningTemplate, width: '60rem', height: '35rem' },
                                    { image: imageConfigureModelForm, width: '60rem', height: '35rem' },
                                ]}
                                description="Next, a pre-trained Model is configured and selected from the existing model library. Depending on the need, a custom model created by a Data Scientist can also be uploaded via UI. "
                            />
                            <ImageSubsection
                                title=""
                                images={[
                                    { image: imageListDefaultValidation, width: '60rem', height: '35rem' },
                                    { image: imageModelValidationPreview, width: '60rem', height: '35rem' },
                                ]}
                                description="Under Validation, the performance of different models can be compared and validated by accuracy, precision, and recall while having an overview of correct and incorrect predictions. The best-performing model can be picked for deployment."
                            />
                            <ImageSubsection
                                title=""
                                images={[
                                    { image: imageListDefault, width: '60rem', height: '35rem' },
                                    { image: imagePublishModelForm, width: '60rem', height: '35rem' },
                                ]}
                                description=" Once a model is published, it can be deployed directly into production. It can also be run asynchronously if needed, ultimately exposing the resulting tailored insights to the end users through Squirro's dashboards."
                            />
                            <ProblemStatement
                                title=""
                                description="The AI Studio also allows feedback collection and processing. Domain experts and data scientists can provide feedback on the labeled training data, whereas the end users can provide feedback on the final labeled project data to improve the quality of the model continuously. Overall, the rapid iterations involving users during the early stages of the model training process and the fast deployment of models drastically increased the data democratization process."
                            />
                            <Solution
                                title="Design Details"
                                description={[
                                    'After many iterations, the final version was built on Figma using Google Material Design components.',
                                    'The navigation particularly stands out. Users can select tabs to begin their journey from any of the five steps. Once inside the step, the tabs navigation is taken over by the breadcrumbs specific to the selected tab. Prominent use of the FAB makes it easy to exit the step and go back to the home page.',
                                    'As all steps are interconnected, it is easy to modify any previous or the subsequent tab without exiting the current action and losing the progress.',
                                ]}
                            />
                        </>
                    }
                </div>
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default AIStudio;
