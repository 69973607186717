import * as React from 'react';
import CompactSwitcher from "./components/CompactSwitcher";
import HalfWidthWrapper from './components/HalfWidthWrapper';
import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import TopNav from '../../toolbar/TopNav';
import Footer from "../../toolbar/Footer";
import './AppBuilder.css';
import image_01MobEditorDisplay from '../../images/appbuilder/0.1MobEditorDisplay.jpg';
import image_03MobEditorWidget from '../../images/appbuilder/0.3MobEditorWidget.jpg';
import image_04MobEditorWidget from '../../images/appbuilder/0.4MobEditorWidget.jpg';
import image_06MobEditorSyncButton from '../../images/appbuilder/0.6MobEditorSyncButton.jpg';
import image_07MobEditorSync from '../../images/appbuilder/0.7MobEditorSync.jpg';
import image_11DashboardConfig from '../../images/appbuilder/1.1dashboardconfigc.jpg';
import image_13OnlyOneLayerVisible from '../../images/appbuilder/1.3only_one_layer_visiblec.jpg';
import image_14TopSectionPin from '../../images/appbuilder/1.4TopSectionPin.png';
import image_17LeftSectionScroll from '../../images/appbuilder/1.7LeftSectionScroll.png';
import image_AllSections from '../../images/appbuilder/Allsectionsc.jpg';
import image_GridDisplayOptions from '../../images/appbuilder/GridDisplayOptionsc.jpg';
import image_GridDisplayPositioning from '../../images/appbuilder/GridDisplayPositioningc.jpg';
import image_MobileEditor from '../../images/appbuilder/MobileEditor.png';
import image_OldDashboard from '../../images/appbuilder/OldDashboard.png';
import image_OldEditor from '../../images/appbuilder/OldEditor.png';
import image_SectionsTranslation from '../../images/appbuilder/SectionsTranslation.jpg';
import image_VisibilityCondition from '../../images/appbuilder/VisibilityCondition.png';
import image_WidgetBrowser from '../../images/appbuilder/WidgetBrowser.png';
import video_SquirroDashboardEditorProperties from '../../images/appbuilder/SquirroDashboardEditorProperties.gif';
import video_SquirroDashboardEditorNew from '../../images/appbuilder/SquirroDashboardEditorNew.mp4';
import image_TreePanel from '../../images/appbuilder/TreePanel.png';
import image_GridSize from '../../images/appbuilder/GridSize.png';
import image_ParameterOptions from '../../images/appbuilder/ParameterOptions.png';
import video_GridSize from '../../images/appbuilder/GridSize.mp4';
import image_LayerProperties from '../../images/appbuilder/LayerProperties.png';
import image_111SectionsEditMode from '../../images/appbuilder/1.11SectionEditMode.png';
import video_Google from '../../images/appbuilder/Google.mp4';
import image_SyncOn from '../../images/appbuilder/SyncOn.png';
import image_SyncOff from '../../images/appbuilder/SyncOff.png';
import image_brainMap from '../../images/appbuilder/brainmap.png';
import image_persona1 from '../../images/appbuilder/appbuilder_persona1_2.png';
import image_persona2 from '../../images/appbuilder/appbuilder_persona2_2.png';
import image_persona3 from '../../images/appbuilder/appbuilder_persona3_2.png';
import image_banner from '../../images/appbuilder/appbuilder_banner.png';
import image_widgetLibShort2 from '../../images/appbuilder/widgetlibshort2.png';
import ThreeColumnContainer from './components/ThreeColumnContainer';


const AppBuilder = (props) => {
    const [alignment, setAlignment] = React.useState('short');

    return (
        <div className="mainBox">
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="appBuilder project odd">
                <div className="project-inner">
                    <ImageSubsection
                        title="App Builder"
                        blue={true}
                        banner={true}
                        images={[
                            { image: image_banner },
                        ]}
                        description='An advanced drag and drop app building tool<br/><br/><span style="opacity: 0.6">ROLE:</span> Lead Designer'
                    />
                    <div className="alignmentWrapper">
                        <CompactSwitcher alignment={alignment} setAlignment={setAlignment}/>
                    </div>

                    {alignment === 'short' &&
                        <>
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    title="Introduction"
                                    description="The Squirro Insights Engine platform is an end-to-end solution for building augmented intelligence apps for enterprises. One of its core component is the App Builder, which brings to life any user interface using its robust framework, powerful features, and customization options. It is one of my proudest innovations, where I conceptualized all the components while being the only designer on this project."
                                />
                                <ProblemStatement
                                    title="Dashboard to Apps"
                                    description="The legacy tool was designed to build dashboards. Through my ideas, we worked towards bringing app-ness by adding much-needed navigation and interaction concepts to the App Builder."
                                />
                                <ProblemStatement
                                    title="Problem Statement"
                                    description="Design a new system of building applications that steers away from static dashboards nevertheless uses them as the building blocks. Propose features and components required to build apps. "
                                />
                            </HalfWidthWrapper>
                            <ImageSubsection
                                title="Users"
                                blue={true}
                                noBackground={true}
                                description=""
                                images={[
                                    { image: image_persona3 },
                                    { image: image_persona1 },
                                    { image: image_persona2 },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                noBackground={true}
                                noShadow={true}
                                title="Design Strategy"
                                description="The new tool allows building dashboards, section-by-section, using layers and widgets within a visual editor. With every iteration, I moved closer to the vision of building an actual app using dashboards. Some of the concepts are shown below in the mind map."
                                images={[
                                    { image: image_brainMap },
                                ]}
                            />
                            <HalfWidthWrapper>
                                <TitleBox
                                    title="Concepts"
                                    description=""
                                />
                            </HalfWidthWrapper>
                            <ThreeColumnContainer>
                                <ProblemStatement
                                    title="Dashboard Sections"
                                    description="A section is a base-level component used to design a dashboard layout using the Top, Bottom, Left, Right and Center sections."
                                />
                                <ProblemStatement
                                    title="Dashboard Layers"
                                    description="Layers can be understood as containers for widgets. Each layer can control the rules for its visibility."
                                />
                                <ProblemStatement
                                    title="Widget Library"
                                    description="Widgets are the smallest building blocks used to choose the display data and charts. They can be configured using configuration parameters."
                                />
                                <ProblemStatement
                                    title="Visibility Conditions"
                                    description="Visibility condition is a layer property that allows layers to control their visibility using a condition builder using parameters like Query, Label, Selection, Dashboard Store etc."
                                />
                                <ProblemStatement
                                    title="Resizing Options"
                                    description="To achieve app like experience, I designed resizing options like Autosize widgets to fit frame, Preserve minimum widget size and No auto-sizing."
                                />
                                <ProblemStatement
                                    title=""
                                    description=""
                                />
                            </ThreeColumnContainer>
                            <ImageSubsection
                                blue={true}
                                title=""
                                description=""
                                images={[
                                    { image: image_AllSections },
                                    { image: image_GridDisplayOptions },
                                    { image: image_GridDisplayPositioning },
                                    { image: image_11DashboardConfig },
                                    { image: image_13OnlyOneLayerVisible },
                                ]}
                            />
                            <HalfWidthWrapper>
                                <TitleBox
                                    title="UI Design"
                                    description=""
                                />
                            </HalfWidthWrapper>
                            <ThreeColumnContainer>
                                <ProblemStatement
                                    title="Edit Mode"
                                    description="The edit mode UI designs were inspired by standard design tools like Adobe Creative Suite and Google Data Studio to leverage established patterns. The editor UI has two panels- A list panel and a properties panel."
                                />
                                <ProblemStatement
                                    title="Widget Library"
                                    description="All widgets are listed in a tile view with representative icons under functional categories like items, navigation, filters, search, etc."
                                />
                                <ProblemStatement
                                    title="Properties Panel"
                                    description="The properties panel lists the configuration options for the selected entity, such as dashboard, layer, or widget in the tree panel. "
                                />
                                <ProblemStatement
                                    title="Editor Canvas"
                                    description="Only the selected layer is visible on the canvas while editing the dashboard. Widgets can be dragged and dropped on the workspace, moved around, resized, or duplicated."
                                />
                                <ProblemStatement
                                    title="Theme"
                                    description="The editor has a dark theme and highlights the widgets on a light frame with dotted background. "
                                />
                                <ProblemStatement
                                    title=""
                                    description=""
                                />
                            </ThreeColumnContainer>
                            <ImageSubsection
                                blue={true}
                                title="Old Design"
                                description=""
                                images={[
                                    { image: image_OldEditor },
                                    { image: image_OldDashboard },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="New Design"
                                description=""
                                images={[
                                    { image: image_GridSize },
                                    { image: image_14TopSectionPin },
                                    { image: image_17LeftSectionScroll },
                                    { video: video_GridSize },
                                    { image: video_SquirroDashboardEditorProperties },
                                    { image: image_VisibilityCondition },
                                    { image: image_ParameterOptions },
                                    { video: video_SquirroDashboardEditorNew },
                                    { image: image_TreePanel },
                                    { image: image_WidgetBrowser },
                                    { image: image_widgetLibShort2 },
                                ]}
                            />
                            <HalfWidthWrapper>
                                <TitleBox
                                    title="Transition to Mobile"
                                    description=""
                                />
                            </HalfWidthWrapper>
                            <ThreeColumnContainer>
                                <ProblemStatement
                                    title="Device Detection"
                                    description="The desktop and mobile modes of a dashboard share the same URL. While the desktop mode renders on all web-like and tablet screens, the mobile mode renders on all mobile-like screens."
                                />
                                <ProblemStatement
                                    title="Mobile Dashboard Editor"
                                    description="The widgets must be manually adjusted on mobile using a few ground rules like - The widgets span the entire width on mobile and can only be stacked vertically."
                                />
                                <ProblemStatement
                                    title="Modes Sync"
                                    description="The desktop and mobile modes are in sync by default. Re-syncing is uni-directional. It copies the layers and widgets from the desktop mode to the mobile but not vice versa."
                                />
                                <ProblemStatement
                                    title="Translation of Sections on Mobile"
                                    description="The mobile mode always displays the center section on the screen. The remaining four sections slide into view based on different gestures like scroll up, scroll down, left swipe and right swipe. "
                                />
                                <ProblemStatement
                                    title=""
                                    description=""
                                />
                                <ProblemStatement
                                    title=""
                                    description=""
                                />
                            </ThreeColumnContainer>
                            <ImageSubsection
                                blue={true}
                                title=""
                                description=""
                                images={[
                                    { image: image_SectionsTranslation },
                                    { image: image_04MobEditorWidget },
                                    { image: image_01MobEditorDisplay },
                                    { image: image_03MobEditorWidget },
                                    { image: image_06MobEditorSyncButton },
                                    { image: image_07MobEditorSync },
                                    { image: image_MobileEditor },
                                    { image: image_SyncOn },
                                    { image: image_SyncOff },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Demo"
                                description="Let's see a short demo of how any User Interface can become a reality using the editor. I will replicate the Google Search experience by using Squirro dashboarding concepts in less than 2 minutes!"
                                images={[
                                    { video: video_Google },
                                ]}
                            />
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    title="Reception"
                                    description="This tool became the backbone of showcasing our capabilities in delivering full-fledged applications. It is so easy to use and powerful that it is not limited to building apps. Marketing uses it to build real product videos for marketing campaigns. Sales uses it for sales pitch. Solution engineers use it to build POCs for prospects. Our customers use it to design their own dashboards. Need I say, this magical tool eliminated making mockups from the design process as the designers preferred to build live interactive dashboards that could be shared with the users and tested even before writing any line of code. It became a universal playground for everyone involved in a project and probably the best app builder out there ;)"
                                />
                            </HalfWidthWrapper>
                        </>
                    }

                    {alignment === 'long' &&
                        <>
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="The <a href='https://squirro.com' target='_blank' rel='noreferrer'>Squirro</a> Insights Engine platform is an end-to-end solution for building augmented intelligence apps for enterprises. It gathers data from all internal, external, and premium data sources, detects entities and concepts by applying AI and ML models, and creates dashboards to build standard applications or custom solutions for customers. A core component of the Insights Engine is the App Builder, which brings to life any user interface using its robust framework, powerful features, and customization options. It also allows building user interfaces for mobile devices. It is one of my proudest innovations, where I conceptualized all the components while being the only designer on this project."
                                images={[
                                    { video: video_SquirroDashboardEditorNew },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Dashboard to Apps"
                                description="When I joined Squirro, the end-user interface was designed as a dashboard. It was missing the cohesiveness of an app, which naturally resulted in the orthodox dashboard-heavy user experience from the past generations. Through my contribution and ideas, we worked towards bringing app-ness by adding much-needed navigation and interaction concepts to the App Builder. See screenshots of the old editor below."
                                images={[
                                    { image: image_OldEditor },
                                    { image: image_OldDashboard },
                                ]}
                            />
                            <ProblemStatement
                                title="Problem Statement"
                                description="The next version of the App Builder shall introduce a new system of building applications. It shall come packed with standard components and features required to build apps. It steers away from static dashboards nevertheless uses them as the building blocks."
                            />
                            <ProblemStatement
                                title="Users"
                                description="The tool is primarily meant for dashboard builders. This responsibility is often taken by the project admins or the business analysts from the customer. Developers might also use it for advanced options like uploading a custom widget or writing a theme using the theme editor. The end-users, i.e., the customers' employees, would never use the tool."
                            />
                            <ProblemStatement
                                title="Design Strategy"
                                description="The new tool allows building dashboards, section-by-section, using layers and widgets within a visual editor. It was a massive design project that would set the ground rules for exposing many platform capabilities like search strategies, facets, and query parameters in the UI. With every iteration, I kept refining the system and moved closer to the vision of building an actual app. During the project, I came up with many concepts. Read how they helped us achieve the UX exclusive to apps."
                            />
                            <ProblemStatement
                                title="Dashboard Sections"
                                description="I wanted to have standardized layout options to realize what the app could look like early on. So, I came up with the concept of Sections. A section is a base-level component used to design a dashboard layout. It serves as a container to stack dashboard layers one below the other. The five sections have their assumed uses."
                            />
                            <Solution
                                title=""
                                description={[
                                    'Top Section (Header)',
                                    'Bottom Section (Footer)',
                                    'Left Section Left Filter / Navigation Panel (Filter / Navigation)',
                                    'Right Section (Supplementary data)',
                                    'Center Section (Main feed / Charts)',
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description=""
                                images={[
                                    { image: image_AllSections },
                                    { image: image_11DashboardConfig },
                                ]}
                            />
                            <ProblemStatement
                                title=""
                                description="For example, any standard social media app UI can be replicated by using the left, center, and right sections, where the left section includes tab widgets, the center consists of the feed widget, and the right contains suggestions and advertisement-related widgets. As a rule, the center section contains the main body of information and is always enabled. The other sections are optional."
                            />
                            <ImageSubsection
                                blue={true}
                                title="Section Resize, Pin and Scroll"
                                description="After defining the sections and their uses, I started detailing their behavior. I identified resize, pin, and scroll as the three main properties that would help us move closer to building an app experience. I defined them in much detail. To highlight a few, Sections' width and height can be resized using grab handles."
                                images={[
                                    { image: image_GridDisplayOptions },
                                    { image: image_GridDisplayPositioning },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="On resizing, the percentage distribution of a section is displayed."
                                images={[
                                    { image: image_GridSize },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="Pinning a section always keeps that section in view, even on scroll. It only applies to the top or bottom sections and is thus ideal for headers and footers."
                                images={[
                                    { image: image_14TopSectionPin },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="The Scroll Independently option forces the sections to scroll independently of each other. When turned off, all sections scroll together, triggering a page scroll. On smaller devices, the Left / Right sections can be toggled into view using the toggle button."
                                images={[
                                    { image: image_17LeftSectionScroll },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Dashboard Grid"
                                description="The sections and the widgets snap to the dashboard grid while resizing. I decided to replace the previous 12-column grid with a 48-column grid system to have fine-grained control over the widget sizes. I also tackled edge cases like preventing a section from being resized out of the view by assigning it a minimum width of 3 columns."
                                images={[
                                    { video: video_GridSize },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Dashboard Layers"
                                description="My next experiment was deconstructing an app sitemap into information layers. If every distinct page could be imagined as a layer and the action required to open a page be conceived as a condition, we could crack the code of the core system behind building apps. I proposed a system of dashboard sections consisting of layers with widgets configured to show desired data. Layers can be understood as containers for widgets. Each layer can control the rules for its visibility. It becomes visible only when its visibility conditions are met, thus creating a robust system to show or hide information when interacting with the dashboard. It gives a complete dynamic control of what to display and when."
                                images={[
                                    { image: image_13OnlyOneLayerVisible },
                                ]}
                            />
                            <ProblemStatement
                                title="Grouping widgets inside Layers"
                                description="The first version of the app builder allowed placing widgets on the layer using the grid system. In the subsequent iterations, I developed additional grouping options for greater flexibility and better screen space utilization. Eventually, we built three modes for grouping widgets."
                            />
                            <Solution
                                title=""
                                description={[
                                    'Grid mode snaps widgets to the 48-column grid.',
                                    'The Accordion mode stacks widgets as accordions, an ideal option for widget-heavy layers.',
                                    'The Carousel mode groups widgets using the carousel pattern.',
                                    'The Free Placement mode ignores the grid system and allows widgets to be placed anywhere on the dashboard.',
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description=""
                                images={[
                                    { image: image_LayerProperties },
                                ]}
                            />
                            <ProblemStatement
                                title="Resizing Options"
                                description="Building customer apps using the new tool revealed more improvisation opportunities related to resizing the dashboard. For example, widgets with fixed height would leave empty white space on big screens and hide important information on small screens. Ideally, certain widgets like tabs or search bars should have set heights; others, like result lists and tables, should occupy the entire browser height to have an endless scroll experience. I designed some brilliant layer resizing options to create a seamless resizing experience and control what is displayed above the fold."
                            />
                            <Solution
                                title=""
                                description={[
                                    '<b>No auto-sizing:</b> The layer accommodates all widgets with their original height, thus creating a vertical scroll bar on exceeding the browser height.',
                                    '<b>Preserve minimum widget size:</b> This method respects the widget height set in the editor. Widgets on such a layer grow in height, filling up the empty space, but do not shrink below their original height.',
                                    '<b>Autosize widgets to fit frame:</b> This option shrinks and grows the widgets inside the layer to ensure they are always visible.',
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Visibility Conditions"
                                description="Visibility condition is a layer property that allows layers to control their visibility using a condition builder. Its first version consisted of predefined simple parameters, like Query and Label, to build rules. With every customer project, I identified the gaps and extended the builder to include more parameters like Selection, Communities, and Dashboard Store, to name a few. Each parameter has operators to build conditions. For example, the Query option allows setting visibility conditions based on the user search query with operators like contains, does not contain, is empty, is not empty, etc. The Label option allows setting visibility conditions based on the label value selected by the user."
                                images={[
                                    { image: image_VisibilityCondition },
                                    { image: image_ParameterOptions },
                                ]}
                            />
                            <ProblemStatement
                                title="Dashboard Store"
                                description="The Dashboard Store deserves a special mention of all visibility condition parameters as it truly revolutionizes building conditions when the other parameters are unavailable. A dashboard store is a store for key-value pairs. It can be used by visibility condition builder to react to the changes to the store value and display different layers, thus providing an app-like experience. For example, showing three different layers under three tabs would require creating a layer with a tabs widget and configuring each tab with a store key value. Next, the layer visibility conditions should be configured using the same store key value. Selecting tabs would then change the store value and display the layer meeting the condition. I contributed to this topic by integrating this feature into the tool and using it to link widgets and layers. To learn more about using the Dashboard Store to visualize layers, see <a href='https://docs.squirro.com/en/latest/technical/dashboards/dashboard-store.html#dashboards-store' target='_blank' rel='noreferrer'>Dashboard Store</a>."
                            />
                            <ImageSubsection
                                blue={true}
                                title="Widget Library"
                                description="The edit mode UI designs were inspired by standard design tools like Adobe Creative Suite and Google Data Studio to leverage established patterns and not unnecessarily reinvent the UI. The editor UI has two panels- A tree panel where all dashboard sections, layers, and nested widgets are listed and a properties panel where they can be configured."
                                images={[
                                    { image: image_TreePanel },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="New widgets can be added from the widget library, where all widgets are listed in a tile view with representative icons under functional categories like items, navigation, filters, search, etc. The custom widgets uploaded by the customers are listed under the last category, Custom. The sequence of the categories choreographs the entire experience of building the dashboard. Widgets can be easily placed on the frame using drag and drop."
                                images={[
                                    { image: image_WidgetBrowser },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Properties Panel"
                                description="The properties panel lists the configuration options for the selected entity, such as dashboard, layer, or widget in the tree panel. Similar data configuration and visualization parameters are grouped, with the most relevant parameters at the top and the least at the bottom. I decided to pin the tree panel to the left and the properties panel to the right. However, the usability test results revealed that many users preferred the properties panel on the left to reduce the mouse movement between the two panels. I used this insight to design the L/R toggle button to switch the position of the properties panel."
                                images={[
                                    { image: video_SquirroDashboardEditorProperties },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Editor Frame"
                                description="The old editor allowed selecting a component from the tree panel but lacked an intuitive way of selecting it by simply clicking on it. I designed two ways to navigate to the sections, layers, and widgets: from the tree panel or the editor frame. The frame displays all sections configured in the dashboard. Simply clicking on a section displays the layers inside it. The remaining sections get hatched to allow focus on the selected section. On clicking on the hatched area, the user returns to the sections layout and can navigate to other sections. An app can include many layers. To manage them easily, only the selected layer is visible while editing the dashboard."
                                images={[
                                    { image: image_111SectionsEditMode },
                                ]}
                            />
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    title="The Visual Style"
                                    description="The visual design of the editor is inspired by the standards that have stuck around the generation of editors. The editor follows a dark theme and highlights the widgets on a light frame with dotted background. Its multitudes of tools are packed compactly to ease the scanning of the available options. Widgets can be dragged and dropped on the frame, moved around, resized, or duplicated."
                                />
                            </HalfWidthWrapper>
                            <ImageSubsection
                                blue={true}
                                title="Mobile Dashboards"
                                description="Once we had the App Builder ready, I took on the challenge of creating the rules for mobile dashboard creation. The user experience offered on a desktop app differs significantly from the one provided on the mobile app. The widgets are primarily designed for desktop dashboards and must be manually adjusted and reconfigured on mobile. I laid out a few ground rules. The widgets span the entire width on mobile and can only be laid vertically for simplification. The desktop and mobile modes of a dashboard share the same URL. While the desktop mode renders on all web-like and tablet screens, the mobile mode renders on all mobile-like screens. Users can switch to the mobile version and resize, reorder, and reconfigure the widgets."
                                images={[
                                    { image: image_01MobEditorDisplay },
                                    { image: image_03MobEditorWidget },
                                    { image: image_04MobEditorWidget },
                                    { image: image_06MobEditorSyncButton },
                                    { image: image_07MobEditorSync },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="Dashboards are often modified with feature releases. It is easy to track changes across the two modes for minor modifications, but for major modifications, it becomes a daunting task. I devised the concept of sync between the modes to solve this problem. As per this concept, the two modes are in sync by default. Once the mobile mode is edited, it is no longer in sync with the web mode. Re-syncing is uni-directional, i.e., it copies the layers and widgets from the desktop mode to the mobile and not vice versa. This feature helped save a lot of time when redesigning or modifying an existing dashboard."
                                images={[
                                    { image: image_MobileEditor },
                                    { image: image_SyncOn },
                                    { image: image_SyncOff },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Translation of Desktop Sections on Mobile"
                                description="I also proposed a clever way for the desktop dashboards to translate to mobile experience for consistent user experience and to bring completeness to the app-building logic. The mobile mode always displays the center section on the screen. All four sections slide into view based on different gestures. The top section (used for sub-tabs, page header, etc.) slides into the view by scrolling up the content below the fold, showing the intent that the user wants to go to the top of the page and probably navigate. The bottom section (used for toolbar, footer, etc.) slides into view when the user scrolls down the content. If desired, the top and the bottom sections can be configured to be pinned so they are always in view. The left and right sections slide into view on the swipe gestures from the left/right edge of the screen."
                                images={[
                                    { image: image_SectionsTranslation },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Demo Experiment"
                                description="Let's see a short demo of how any User Interface can become a reality using the editor. I will replicate the Google Search experience by using Squirro dashboarding concepts in less than 2 minutes!"
                                images={[
                                    { video: video_Google },
                                ]}
                            />
                            <ProblemStatement
                                title="Reception"
                                description="This tool became the backbone of showcasing our capabilities in delivering full-fledged applications. It is so easy to use and powerful that it is not limited to building apps. Marketing uses it to build real product videos for marketing campaigns. Sales uses it for sales pitch. Solution engineers use it to build POCs for prospects. Our customers use it to design their own dashboards. Need I say, this magical tool eliminated making mockups from the design process as the designers preferred to build live interactive dashboards that could be shared with the users and tested even before writing any line of code. It became a universal playground for everyone involved in a project and probably the best app builder out there ;)"
                            />
                        </>
                    }
                </div>
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default AppBuilder;
