import "./AboutProject.css";

// title, description, imageUrl, backgroundColor, odd
const AboutProject = (props) => {
    const textPanel = () =>
        (
            <>
                <a className="title" href={`/${props.redirectUrl}`}>
                    {props.title}
                </a>
                <div className="aboutMeText">
                    {props.description}
                </div>
            </>
        );

    const imagePanel = () =>
        (
            <>
                <a className="photo" href={`/${props.redirectUrl}`}>
                    <img src={props.imageUrl} className="photo-image" alt="Banner"/>
                </a>
            </>
        );

    return (
        <div className={`AboutProject ${props.odd ? 'odd' : ''}`}>
            <div className="leftPanel">
                {props.odd ? textPanel() : imagePanel()}
            </div>
            <div className="rightPanel">
                {props.odd ? imagePanel() : textPanel()}
            </div>
        </div>
    );
};

export default AboutProject;
