import './App.css';
import {
    BrowserRouter,
    useLocation,
    Routes,
    Route,
} from "react-router-dom";
import Home from './Home';
import AIStudio from './pages/projects/AIStudio';
import FoodSafety from './pages/projects/FoodSafety';
import DesignManagement from './pages/projects/DesignManagement';
import Blogs from './Blogs';
import ActivityTracking from './pages/projects/ActivityTracking';
import AppBuilder from './pages/projects/AppBuilder';
import RiceAI from './pages/projects/RiceAI';
import { AnimatePresence } from "framer-motion";

function App() {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route index element={<Home />} />
                <Route path="/mgmt" element={<DesignManagement />} />
                <Route path="/aistudio" element={<AIStudio />} />
                <Route path="/foodsafety" element={<FoodSafety />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/appbuilder" element={<AppBuilder />} />
                <Route path="/activity" element={<ActivityTracking />} />
                <Route path="/riceai" element={<RiceAI />} />
            </Routes>
        </AnimatePresence>
    )
}

const Root = () => <BrowserRouter><App /></BrowserRouter>;

export default Root;
