import './Solution.css';

// title, description
const Solution = (props) => {
    let arrKey = 0;

    const processArray = (arr) => (
        <ul key={++arrKey}>
            {arr.map((arrElement) => {
                return Array.isArray(arrElement)
                    ? processArray(arrElement)
                    : <li key={++arrKey} dangerouslySetInnerHTML={{__html: arrElement}}/>;
            })}
        </ul>
    );

    return (
        <div className={`Solution ${props.smallTitle ? 'small' : ''}`}>
            <h2>
                {props.title}
            </h2>
            <ul className="description">
                {processArray(props.description)}
            </ul>
        </div>
    );
};

export default Solution;
