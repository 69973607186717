import './TopNav.css';
import photo from '../images/shivanivisencrop.jpg';

// activeIndex, noBackground
const TopNav = (props) => {
    return (
        <div className={`TopNav ${props.noBackground ? 'noBackground' : ''}`}>
            <a href="/">
                <img src={photo} className="photo" alt="Shivani"/>
            </a>
            <a className={props.activeIndex === 0 ? 'active entry' : 'entry'} href="/">
                Work
            </a>
            <a className={props.activeIndex === 1 ? 'active entry' : 'entry'} href="/blogs">
                Blogs
            </a>
            <a className={props.activeIndex === 2 ? 'active entry' : 'entry'} href="https://ch.linkedin.com/in/shivani-visen-82918a51" target="_blank" rel="noreferrer">
                Resume
            </a>
        </div>
    );
};

export default TopNav;
