
import TopNav from './toolbar/TopNav';
import './Blogs.css';
import Blog from './pages/projects/components/Blog';
import blog1 from './images/blog1.webp';
import blog2 from './images/blog2.webp';
import blog3 from './images/blog3.webp';
import blog4 from './images/blog4.webp';
import Footer from './toolbar/Footer';
import ScrollToTop from 'react-scroll-to-top';
import { useWindowSize } from "@uidotdev/usehooks";

const Blogs = (props) => {
    const size = useWindowSize();
    const offsetedHeight = `calc(${size.height}px - 24rem)`;
    return (
        <>
            <div className="Blogs" style={{minHeight: offsetedHeight}}>
                <TopNav activeIndex={1}/>
                <div className="blogsContent" style={{minHeight: offsetedHeight}}>
                    <Blog
                        imageUrl={blog4}
                        title="Want to advance your career in tech? It’s all about trust"
                        description="Read More"
                        redirectUrl="https://wearetechwomen.com/want-to-advance-your-career-in-tech-its-all-about-trust"
                    />
                    <Blog
                        imageUrl={blog1}
                        title="Embrace Abbreviations to Enhance UI"
                        description="Read More"
                        redirectUrl="https://squirro.com/squirro-blog/17374"
                    />
                    <Blog
                        imageUrl={blog2}
                        title="U, UX and the Fireman's Pole to Cognitive Search"
                        description="Read More"
                        redirectUrl="https://squirro.com/squirro-blog/x-ux-and-the-firemans-pole-to-cognitive-search"
                    />
                    <Blog
                        imageUrl={blog3}
                        title="Material Design: Stop Stalking, Start Following"
                        description="Read More"
                        redirectUrl="https://squirro.com/squirro-blog/material-design-stop-stalking-start-following"
                    />
                </div>
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </>
    );
}

export default Blogs;
