import "./AboutMe.css";
import movie from '../images/marathon3cc.mp4';
import ReactTyped from "react-typed";
import ReactCountryFlag from "react-country-flag";
import Companies from "./projects/components/Companies";
import arrowup from '..//images/companies/arrowup.png';


// scrollToWork
const AboutMe = (props) => {
    return (
        <div className="AboutMe">
            <div id="myVideo">

            </div>
            {/* <video autoPlay muted loop id="myVideo">
                <source src={movie} type="video/mp4"/>
            </video> */}
            <div className="leftPanel">
                <div className="title">
                    Shivani Visen
                </div>
                <div className="subtitle">
                    <span>
                        UX Designer Based In 🇨🇭
                    </span>
                </div>
                <div className="subtitle2 mt">
                    <span>
                        Design, for me, is a wonderful opportunity to bring order to our otherwise chaotic minds, at least when we interact with digital products. I have strived to apply the power of design to many complex concepts in the fields of ML, AI, and, quite recently, Blockchain.
                    </span>
                </div>
            </div>
            <div className="rightPanel">
                <Companies/>
                <div className="subtitle3 mt">
                    <div>
                        <img class="arrowup" src={arrowup} alt="Arrow Up"/>
                    </div>
                    <span>
                        Some of my happy customers
                    </span>
                </div>
                {/* <div className="aboutMeText">
                    <ReactTyped
                        strings={["Designer", "Leader", "Eternal Optimist"]}
                        typeSpeed={100}
                        loop
                        backSpeed={20}
                        cursorChar="_"
                        showCursor={true}
                    />
                </div> */}
                {/* <div className="seeMyWork" onClick={props.scrollToWork}>
                    | See My Work |
                </div> */}
                {/* <a className="cheerMe" href="https://www.zuerichmarathon.ch/" target="_blank" rel="noreferrer">
                    {'>'}&nbsp;&nbsp;Cheer for me at Zurich Marathon 13.04.2025!&nbsp;&nbsp;{'<'}
                </a> */}
            </div>
        </div>
    );
};

export default AboutMe;
