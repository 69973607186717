import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import TopNav from '../../toolbar/TopNav';
import ReactCodeInput from 'react-code-input';
import { useState } from "react";
import image_Persona1 from '../../images/foodsafety/Persona1.jpg';
import image_Persona2 from '../../images/foodsafety/Persona2.jpg';
import image_Persona3 from '../../images/foodsafety/Persona3.jpg';
import image_UserJourneyMapping from '../../images/foodsafety/UserJourneyMapping.jpg';
import image_UserVsBusinessNeeds from '../../images/foodsafety/UserVsBusinessNeeds.jpg';
import image_UserJourneyMappingCleaned from '../../images/foodsafety/UserJourneyMappingCleaned.png';
import image_EmailNewsletter from '../../images/foodsafety/EmailNewsletter.png';
import image_EventsV1 from '../../images/foodsafety/Events_v1.png';
import image_HomeScreenV1 from '../../images/foodsafety/HomeScreenV1.png';
import image_MarketNewsMock from '../../images/foodsafety/MarketNewsMock.png';
import image_RiskAnalysisTeaser from '../../images/foodsafety/RiskAnalysisTeaser.png';
import image_RiskAnalysisMock from '../../images/foodsafety/RiskAnalysisMock.png';
import image_1Png from '../../images/foodsafety/1.png';
import image_2Png from '../../images/foodsafety/2.png';
import video_Community360Mp4 from '../../images/foodsafety/Community360.mp4';
import image_CommunityItems from '../../images/foodsafety/CommunityItems.png';
import image_CommunityPage from '../../images/foodsafety/CommunityPage.png';
import image_EmptyState from '../../images/foodsafety/EmptyState.png';
import video_Explore from '../../images/foodsafety/Explore.mp4';
import video_ForYou from '../../images/foodsafety/ForYou.mp4';
import image_ActivityTracking1 from '../../images/foodsafety/ActivityTracking1.png';
import image_ActivityTracking2 from '../../images/foodsafety/ActivityTracking2.png';
import video_FoodSafetySquirro from '../../images/foodsafety/FoodSafetySquirro.mp4';
import image_UserInterview from '../../images/foodsafety/UserInterview.png';
import Footer from "../../toolbar/Footer";
import { useWindowSize } from "@uidotdev/usehooks";
import './FoodSafety.css';


const FoodSafety = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('authorized'));
    const size = useWindowSize();

    const validateCode = (code) => {
        const SUPER_SAFE_ACCESS_CODE = '250386';
        if (code === SUPER_SAFE_ACCESS_CODE) {
            setIsLoggedIn(true);
            localStorage.setItem('authorized', true);
        }
    };

    return (
        <div className="mainBox">
            {!isLoggedIn &&
                <div className="passwordNeeded" style={{height: size.height}}>
                    <div>This work is confidential, and requires an access code.</div>
                    <ReactCodeInput type='text' fields={6} onChange={validateCode}/>
                    <div><a href="mailto:shivani.visen@gmail.com?subject=Request%20access%20code">Request code</a></div>
                </div>
            }
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="foodSafety project odd">
                {isLoggedIn &&
                    <div className="project-inner">
                        <TitleBox
                            title="Food Safety <span class='cursive'>- Lead Designer</span>"
                            description="The food safety app is a digital service designed for food safety managers. It monitors all relevant food safety news from international food authorities, news, web posts, and social media trends and proactively alerts the users about potential food safety risks to their relevant food category, products, and ingredients. The pre-made analytical dashboards facilitate risk evaluation, production decisions, preventive measures, and safeguarding of brand reputation by containing the damage caused by food safety incidents like outbreaks, product recalls, and biological, chemical, and physical hazards. I designed this product for Squirro's customer, a leader in the food industry."
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { video: video_FoodSafetySquirro, small: true },
                            ]}
                            description=""
                        />
                        <ProblemStatement
                            title="Problem Statement"
                            description="In times where 30% of all food is lost or wasted while 800 million people are starving, this food safety app should increase food safety and availability by capturing market intelligence and amalgamating it with their industry expertise and knowledge base to offer greater efficiency in food value chains in the global food systems."
                        />
                        <ProblemStatement
                            title="Design Workshops"
                            description="Before kicking off the UX designs, I planned and facilitated many design workshops with our customers to understand their business requirements, end-user needs, user personas, and the touch points in the assumptive user journey. The workshop participants were the Head of Food Safety, Food Sponsorers, and the Project Manager."
                        />
                        <ProblemStatement
                            title="Customer Business Needs and Goals"
                            description="To understand the value exchange between the customer and their users by creating a food safety app, it was essential to understand the customers' business needs and their users' goals. We identified two main business goals:"
                        />
                        <Solution
                            title="Market positioning"
                            smallTitle={true}
                            description={[
                                'Position themselves as an innovation leader in the industry.',
                                'Add credibility in the area of food safety. ',
                                'Capture market intelligence and user pain points on food safety.',
                                'Learn how to run and maintain digital intelligence services.',
                            ]}
                        />
                        <Solution
                            title="Commercial value"
                            smallTitle={true}
                            description={[
                                'Generate money.',
                                'Attract more users. ',
                                'Create a new digital service and contribute to the revenue target.',
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { image: image_UserVsBusinessNeeds, small: true },
                            ]}
                            description=""
                        />
                        <ProblemStatement
                            title="User Needs and Goals"
                            description="I asked the participants to describe the end user's needs and goals. All points were further grouped into similar categories:"
                        />
                        <Solution
                            title="Knowledge base"
                            smallTitle={true}
                            description={[
                                'Get an overview of the relevant food industry, items, and hazards.',
                                'Get reliable and relevant information.',
                            ]}
                        />
                        <Solution
                            title="Risk management"
                            smallTitle={true}
                            description={[
                                'Get early warnings about hazards and should be able to avoid crises, e.g., recalls.',
                                'Find information for specific food or hazards for risk analysis.',
                                'Calculate risk by analyzing what has happened in a similar food industry, what the chances are it happens to me, and how I can avoid it.',
                            ]}
                        />
                        <Solution
                            title="Solution recommendation"
                            smallTitle={true}
                            description={[
                                'Provide recommendations on dealing with a food hazard.',
                                'Notify on detecting early rumors and scandals from social media, e.g., Twitter.',
                                'Find expert information.',
                                'Report Compilation.',
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                            ]}
                            description=""
                        />
                        <ImageSubsection
                            blue={true}
                            title="Persona 1 (primarily females): Food Safety and Quality Officer in a Food Company"
                            images={[
                                { image: image_Persona1, long: true, small: true },
                            ]}
                            description="Lisa, 40 years old, is a food safety and quality officer in a food company.<p>She is responsible for preventing food hazards and incidents. Her typical task is analyzing labs for more information regarding probable hazards. She works in her office on a desktop. She has to answer many phone calls and emails where their customers want to know how to deal with a problem, such as 'how to deal with fibionil.' She doesn't have answers to such questions readily prepared. It takes too long for her to react to affecting news in all journals and portals, as she must monitor official data and social media rumors. Another problem she faces at work is a big disconnect between her and the plant manager. She wants to be proactive regarding a hazard and take early measures in the factory, which is a big challenge as she must approach the plant manager with trustworthy news and possible solutions.<p>Her business responsibilities are HACCP (Hazard Analysis and Critical Control Points), its implementation, and keeping it up to date. Her bosses, usually the CEO and CFO, are concerned about potential incidents and their brand reputation. She must spot if something is going wrong in their supply chain early."
                        />
                        <ImageSubsection
                            blue={true}
                            title="Persona 2: Govt. Representative for Food Safety and Quality"
                            images={[
                                { image: image_Persona2, small: true },
                            ]}
                            description="John, 44 years old, is a govt representative for food safety and quality in the FDA (Food and Drug Administration), USA. He is responsible for public health advisory. He often needs to react in a crisis and make clear the official position on the food-related scandals.<p>He primarily works on his computer in his office, where he has access to expert databases but lacks information on technical solutions and best practices. In his business, he needs guidance for making policies and knowing the industry perspective. He can benefit from a user-friendly tool that shows him the state of the art in all food safety-related areas. His using the food safety app would be an endorsement for the customer."
                        />
                        <ImageSubsection
                            blue={true}
                            title="Persona 3 (primarily males): Plant Manager"
                            images={[
                                { image: image_Persona3, small: true },
                            ]}
                            description="Martin, 52 years old, works in Austria. He is responsible for food production and quality at his company and is active in the Austrian Food Safety Association. He works in a small extrusion plant producing cereals using customer's equipments. His objective is to make sure that the plant runs as designed. He always has a lot of different tasks and is always busy. He is not very familiar with information technology and analytics tools."
                        />
                        <ImageSubsection
                            blue={true}
                            title="User Journey Mapping"
                            images={[
                                { image: image_UserJourneyMapping },
                                { image: image_UserJourneyMappingCleaned, small: false },
                            ]}
                            description="I asked the participants to map the desired user journey by mapping all touchpoints between the end user and the system from the very start till the end for assumptive designs. Parallel to the touchpoints, user actions, and thoughts were plotted. For touchpoints resulting in a negative experience, we identified opportunities to improve the user experience."
                        />
                        <ImageSubsection
                            blue={true}
                            title="Design Strategy"
                            images={[
                                { image: image_MarketNewsMock, small: false },
                                { image: image_RiskAnalysisMock, small: false },
                            ]}
                            description="For the scope of the MVP, we focused only on Persona 1, i.e., the Food Safety and Quality Officer in a food company. The first rumors are spread on social media when food-related hazards occur. The claims are eventually confirmed or discredited by food safety authorities like RASFF or FDA. However, this might take a few days or even weeks. Meanwhile, a lot of food wastage and loss of brand reputation might occur. We wanted to track such incidents early on and proactively notify users about the developing stories so they could take appropriate measures. Users want to monitor the official news and the unofficial news sources. They also want to dive deep into any particular product or hazard, analyze the risk, and understand reflected sentiments. These were the cues we used to create the early mockups."
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { image: image_HomeScreenV1 },
                                { image: image_EventsV1 },
                                { image: image_RiskAnalysisTeaser },
                            ]}
                            description="We tested the MVP designs with our customers. Based on their feedback, we prioritized event detection as the most essential feature. Therefore, we focused on building event notification cards that sit on top of the page. Users can investigate an event, filter the news feed by any food category, monitor trending products and hazards, and use metrics on outbreaks and recalls for reporting."
                        />
                        <Solution
                            title=""
                            description={[
                                'After collecting more feedback from the users, we worked on improvement areas:',
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { image: image_UserInterview },
                            ]}
                            description=""
                        />
                        <Solution
                            title=""
                            description={[
                                'Food products taxonomy should be used for data tagging and filtering the dashboards. Such structure will improve the quality of the events detected. It gave me an opportunity to use colorful food imagery and facilitate category identification using pictures.',
                                'Users should be able to subscribe to the food products and hazards of their interest to tailor their news feeds and get automated email alerts.',
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { image: image_1Png },
                                { image: image_2Png },
                                { image: image_CommunityItems },
                                { image: image_EmptyState },
                            ]}
                            description=""
                        />
                        <Solution
                            title=""
                            description={[
                                'Leveraging the taxonomy and data tagging, we decided to introduce the community page concept (similar to a person\'s or company page in social media apps). It allowed us to improve user engagement by prompting them to explore and subscribe to communities. The user confidence in the app also increased as it became easy to navigate to a product or hazard page by clicking on their name or picture anywhere in the app.',
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { video: video_Explore, small: true },
                            ]}
                            description=""
                        />
                        <div className="iframe-wrapper" style={{marginTop: '-3rem'}}>
                            <iframe src='https://www.youtube.com/embed/GWvNu_J8lSE'
                                frameBorder='0'
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'
                            />
                        </div>
                        <Solution
                            title="Design Details and the Evolution of a Master Dashboard Concept"
                            description={[
                                'After many iterations, the final version was built on Figma using Google Material Design components.',
                                'I extensively researched navigation patterns by studying other apps with a page concept. I inferred that one can navigate to a community page in various ways depending on the need.',
                                [
                                    'One can use global search typeahead for quick navigation and click on the suggested community page link.',
                                    'One can select communities from the community list widget, which displays a list of all communities and allows users to subscribe to them.',
                                    'One can be prompted to go to a community page when it is mentioned in an alert or a notification.',
                                ]
                            ]}
                        />
                        <ImageSubsection
                            blue={true}
                            title=""
                            images={[
                                { image: image_CommunityPage, small: true },
                            ]}
                            description=""
                        />
                        <Solution
                            title=""
                            description={[
                                'Whatever the navigation pattern, the end destination is the same – the community page. ',
                                'As there are many ways to navigate to a community page, the community page itself does not belong to any pre-defined dashboard. Therefore, no tab is highlighted in that case. ',
                                'This behavior is the same as displaying a Master dashboard. Therefore, it builds the case that the community dashboard should be built only once and assigned the property of a master dashboard.',
                                'Thus, I came up with the concept of a Master Dashboard, a dashboard that can be invoked from many places and via many actions. But it should be built only once to be easy to configure and have consistency in the designs. ',
                            ]}
                        />
                        <ImageSubsection
                            title=""
                            blue={true}
                            images={[
                                { video: video_ForYou, small: true },
                                { video: video_Community360Mp4, small: true },
                            ]}
                            description=""
                        />
                        <ImageSubsection
                            title=""
                            blue={true}
                            images={[
                                { image: image_ActivityTracking1, small: false },
                                { image: image_EmailNewsletter, long: true, small: true },
                                { image: image_ActivityTracking2, small: false },
                            ]}
                            description="After analyzing user activity data, we found that the users used email newsletters as the primary product. Therefore, we revamped the newsletter design to include daily highlights, alerts, and metrics. Clicking on it takes the user to the dashboards to analyze the story further."
                        />
                        <ProblemStatement
                            title="'Did You Know' Fun Facts Design"
                            description="During customer interviews and factory visits, I heard many interesting fun facts about food and wanted to integrate them into the designs. I pitched the idea to the customer and got it approved. I decided to replace the empty search page illustration with the one-liner fun facts to create moments of delight. The fun facts displayed at any instant were dynamically selected from a list compiled beforehand. I created the following categories."
                        />
                        <Solution
                            title=""
                            description={[
                                '<b>Self-promotion or the feel-good factor.</b> An opportunity to promote customers to their user base and to spread the philanthropic work they do.',
                                [
                                    'Did you know that 60% of the world\'s wheat is processed on (customer name) machines?',
                                    'Did you know that the first food processing machine (customer name) set up was to separate wheat from the chaff in 1880? Yes, we are more than 100 years old!',
                                ],
                                '<b>Historical fun facts.</b> To delight the users with some food-related fun facts and make them realize that they learned something new today',
                                [
                                    'Did you know that cacao seeds were used as coins by the Mayans? One slave would cost 100 cacao seeds, and a prostitute would cost 10.',
                                    'Did you know that coffee was discovered accidentally by goats? An Ethiopian goatherd noticed his goats to be unusually cheerful and energetic. He followed them to find out that the goats were devouring the leaves and beans of a particular tree.',
                                ],
                                '<b>Statistics</b>',
                                [
                                    'Did you know that Switzerland eats more chocolate than anywhere else? They consume 10 kg per person per year.',
                                    'Did you know that rice is one of the two most commonly consumed food items? 50% of the world\'s population consumes them every day!',
                                ],
                                '<b>Health-related</b>',
                                [
                                    'Did you know that macadamia nuts are toxic to dogs? Even as little as 2.2gm per kilogram of dog\'s weight can cause damage.',
                                    'Did you know that feeding raw food to your dog instead of dry food increases their life expectancy by three years?',
                                ],
                                '<b>Festival / Tradition-based facts.</b> To find interesting facts related to events, festivals, etc., like Thanksgiving, Easter, Christmas, and Octoberfest, where a particular type of food is eaten as a part of the celebration. This increases the relevancy of the facts, making users feel, "Nice, I got to know this right in time."',
                                [
                                    'Did you know the salmonella outbreak scare before every Thanksgiving can be reduced by 20% just by having a perfectly sterile environment and temperature control?',
                                ],
                            ]}
                        />
                    </div>
                }
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default FoodSafety;
