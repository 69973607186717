import './Footer.css';

const Footer = (props) => {

    return (
        <div className="Footer">
            <div className="textBox">
                <a className="email" href="mailto:shivani.visen@gmail.com">shivani.visen@gmail.com</a> | Made with <span className="heart">♥</span> in Switzerland
            </div>
        </div>
    );
};

export default Footer;
