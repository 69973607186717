import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import TopNav from '../../toolbar/TopNav';
import Footer from "../../toolbar/Footer";
import './ActivityTracking.css';
import images_BOE1 from "../../images/activity/BOE_1.png";
import images_BOE2 from "../../images/activity/BOE_2.png";
import images_Candriam1 from "../../images/activity/Candriam_1.png";
import images_Candriam2 from "../../images/activity/Candriam_2.png";
import images_Dashboards from "../../images/activity/Dashboards.png";
import images_Filtered from "../../images/activity/Filtered.png";
import images_Items from "../../images/activity/Items.png";
import images_MetricsOnTop from "../../images/activity/MetricsOnTop.png";
import images_Overview from "../../images/activity/Overview.png";
import images_Searches from "../../images/activity/Searches.png";
import images_SpacesMenu from "../../images/activity/SpacesMenu.png";
import images_Users from "../../images/activity/Users.png";
import images_Glossary1 from "../../images/activity/GlossaryA.png";
import images_Glossary2 from "../../images/activity/GlossaryB.png";
import images_ProjectManager1 from "../../images/activity/Project_managerA.png";
import images_ProjectManager2 from "../../images/activity/Project_managerB.png";
import images_ProjectManager3 from "../../images/activity/Project_managerC.png";
import images_SystemAdmin from "../../images/activity/System_admin.png";
import images_ActivityTrackingTemplate from "../../images/activity/ActivityTrackingTemplate.png";
import video_ActivityTracking from "../../images/activity/ActivityTrackingc.mp4";

const ActivityTracking = (props) => {
    return (
        <div className="mainBox">
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="activityTracking project">
                <div className="project-inner">
                    <TitleBox
                        title="Activity Tracking <span class='cursive'>- Lead Designer / PM</span>"
                        description="We envisioned a three-layered approach to understanding how users use <a href='https://squirro.com' target='_blank' rel='noreferrer'>Squirro</a> products: Qualitative analysis, Quantitative analysis, and Usability testing. These three data points together projected our success and shortcomings in delivering value. Enterprise customers are apprehensive about using commercial analytics tools due to data security concerns. It motivated us to build our own activity-tracking technical infrastructure to measure and report desired metrics on user activity and product usability."
                    />
                    <ImageSubsection
                        title=""
                        description=""
                        images={[
                            { video: video_ActivityTracking },
                        ]}
                    />
                    <ImageSubsection
                        title="Problem Statement"
                        description="Squirro app templates shall be shipped with pre-built activity tracking dashboards for project managers to monitor overall application usage, understand search behavior, and analyze data consumed over time. Built using Squirro dashboards and widgets, the dashboards shall be accessible only to authorized persons. The data should be anonymized to hide the user's identity if needed."
                        images={[{ image: images_ActivityTrackingTemplate }]}
                    />
                    <ImageSubsection
                        title="Research"
                        description="I studied competitor analytics tools like <a href='https://analytics.google.com/analytics/web/#/report-home/a28557532w54340855p55269955' target='_blank' rel='noreferrer'>Google Analytics</a>, <a href='https://app.usefathom.com/share/deasaicp/hilarious+platypus#/?filters=%5B%5D&range=last_7_days&site=114903' target='_blank' rel='noreferrer'>Fathom</a>, and <a href='https://demo.matomo.cloud/index.php?module=CoreHome&action=index&idSite=1&period=day&date=yesterday#?idSite=1&period=day&date=yesterday&segment=&category=Dashboard_Dashboard&subcategory=1' target='_blank' rel='noreferrer'>Motamo</a> and observed common patterns of information hierarchy and clustering metrics. Besides, we had built some ad hoc custom activity tracking solutions for our customers. After a thorough analysis and many customer interviews, I identified that the most critical data clusters were around Users, Dashboards, Searches, and Items, forming the basis of the designs to follow."
                        images={[
                            { image: images_BOE1 },
                            { image: images_BOE2 },
                            { image: images_Candriam1 },
                            { image: images_Candriam2 },
                        ]}
                    />
                    <ImageSubsection
                        title="Glossary"
                        description="The product analytics tools track an exhaustively long list of metrics. Many of those did not apply to our product suite, especially the ones related to demography, sector, and advertising. Our customers and the internal teams were familiar with the common ones, but there was much confusion about their meaning. To bring everyone on the same page and to create a common vocabulary, I made a curated glossary of metrics relevant to our product so that we only invest in measuring the important ones."
                        images={[
                            { image: images_Glossary1 },
                            { image: images_Glossary2 },
                        ]}
                    />
                    <ProblemStatement
                        title="Users"
                        description="As a part of writing the Activity Tracking MRD (Market Requirement Document), I identified three types of users who would benefit from the feature and wrote user stories for each persona. I validated them with the customers and used their feedback to prioritize the metrics for the MVP version."
                    />
                    <ImageSubsection
                        title="Project Manager"
                        smallTitle={true}
                        description="Project managers define KPIs, goals, and milestones and manage the project lifecycle. They shall monitor the user activities by looking at quantitative data to measure the project's success and track user satisfaction. They shall use it to make data-informed decisions and to report project status to the project sponsors."
                        images={[
                            { image: images_ProjectManager1 },
                            { image: images_ProjectManager2 },
                            { image: images_ProjectManager3 },
                        ]}
                    />
                    <ImageSubsection
                        title="System Administrator"
                        smallTitle={true}
                        description="They are responsible for the performance of various backend services and resolving errors or outages. They shall have quick access to log files to do root cause analysis."
                        images={[{ image: images_SystemAdmin }]}
                    />
                    <ProblemStatement
                        title="Buyer"
                        smallTitle={true}
                        description="Buyers are involved in the decision-making process for the Squirro solution and need to be convinced that it is the right thing to buy. They shall be able to view user activity data tied to the project version upgrades. They shall sign off an increase in user subscriptions or the contract renewal by looking at the adoption rate and value delivered to their employees over time."
                    />
                    <ProblemStatement
                        title="Design Strategy"
                        description="In the research phase, I learned that most customers were interested in metrics around four groups: Users, Dashboards, Search terms, and Items. They became the obvious candidates for the top-level dashboard categories. I identified the common denominators of metrics for each category across customer projects. Next, we had to solve the challenge of displaying a vast amount of metrics without overwhelming the users. We chose the progressive display of information approach to reveal information in layers. These layers were designed for three tiers: top-level information for reporting, category-focused information for analysis, and log files for debugging."
                    />
                    <ImageSubsection
                        title=""
                        description="The overview dashboard contains data grouped under the four categories. It is a top-level dashboard to report numbers, figures, and trends by Project Managers to their respective management teams. It is fully interactive and allows sequential filtering, e.g., user group > dashboard > items. It displays important numbers, average time spent, trends over time, distribution, and other relevant information."
                        images={[
                            { image: images_MetricsOnTop },
                        ]}
                    />
                    <ImageSubsection
                        title=""
                        description="The subsequent dashboards show additional category-specific metrics and data in a tabular format."
                        images={[
                            { image: images_Users },
                            { image: images_Dashboards },
                            { image: images_Searches },
                            { image: images_Items },
                        ]}
                    />
                    <ImageSubsection
                        title=""
                        description="Users can navigate to the log files from the page header or inspect some specific logs from the table. It shows the date/time stamp, user activity type, and details."
                        images={[
                            { image: images_Overview },
                            { image: images_Filtered },
                        ]}
                    />
                    <Solution
                        title="Design Details"
                        description={[
                            'The final design was built on Figma using Google Material Design components.',
                            'As the metric names tend to confuse, we used straight-up commonly asked questions as the widget headers, e.g., "How many items are opened?", "Which data sources are used the most?".',
                            'The widgets and the metric type used across the four categories are consistent to improve the ease of use of the dashboard.',
                            'The space icon on the top right of every project allows access to the activity tracking dashboard.'
                        ]}
                    />
                    <ImageSubsection
                        title=""
                        description=""
                        images={[
                            { image: images_SpacesMenu },
                        ]}
                    />
                </div>
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default ActivityTracking;
