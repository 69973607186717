import './ProblemStatement.css';

// title, description
const ProblemStatement = (props) => {
    return (
        <div className={`ProblemStatement ${props.smallTitle ? 'small' : ''}`}>
            <h2>
                {props.title}
            </h2>
            <div className="description" dangerouslySetInnerHTML={{ __html: props.description }}/>
        </div>
    );
};

export default ProblemStatement;
