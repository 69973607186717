import * as React from 'react';
import ScrollToTop from "react-scroll-to-top";
import TitleBox from './components/TitleBox';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import ImageSubsection from './components/ImageSubsection';
import TopNav from '../../toolbar/TopNav';
import Footer from "../../toolbar/Footer";
import image_ABApp from "../../images/design/ABAppOneLineStrategy.png";
import image_DesignOrg from "../../images/design/DesignOrg.png";
import image_GoogleSheets from '../../images/design/GoogleSheetsExample.png';
import image_HealthCheck from '../../images/design/HealthCheck.jpg';
import image_MacroFactors from '../../images/design/MacroFactors.png';
import image_MicroFactors from '../../images/design/MicroFactors.png';
import image_PopupInsideFloatingSection from '../../images/design/PopupInsideFloatingSection.png';
import image_UsabilityTesting from '../../images/design/UsabilityTesting.png';
import image_Screenshot2 from '../../images/design/Screenshot2.png';
import image_Screenshot3 from '../../images/design/Screenshot3.png';
import image_TemplateTable from '../../images/design/TemplateTable.png';
import image_UserJourneyMapping from '../../images/design/UserJourneyMapping.jpg';
import image_UserPersona from '../../images/design/UserPersona.jpg';
import image_Banner from '../../images/design/mgmt_banner.png';
import CompactSwitcher from "./components/CompactSwitcher";
import './DesignManagement.css';
import HalfWidthWrapper from './components/HalfWidthWrapper';


const DesignManagement = (props) => {
    const [alignment, setAlignment] = React.useState('short');

    return (
        <div className="mainBox">
            <TopNav activeIndex={-1} noBackground={true}/>
            <div className="aiStudio project odd">
                <div className="project-inner">
                    <ImageSubsection
                        title="Design Management"
                        blue={true}
                        banner={true}
                        images={[
                            { image: image_Banner, width: '60rem', height: '35rem' },
                        ]}
                        description='As the Head of Design and Product Manager at Squirro, a big part of my responsibility was running the design team efficiently. To do so, I introduced many processes, standards, and best practices. Below is a collection of a few initiatives I pushed for.'
                    />
                    <div className="alignmentWrapper">
                        <CompactSwitcher alignment={alignment} setAlignment={setAlignment}/>
                    </div>

                    {alignment === 'short' &&
                        <>
                            <ImageSubsection
                                blue={true}
                                title="Design Organisation Chart"
                                description="I proposed a hybrid design team structure to the management to explain how designers work within different departments and still report back to the team. "
                                images={[{ image: image_DesignOrg, long: true }]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Design Rationale Documentation"
                                description="Team members would often forget what design decisions were made and why which led to same discussions multiple times. To save time, I created guidelines and templates to document design decisions in a standardized and structured way by compiling macro and micro factors. This initiative was well received and became a standard process for all design projects."
                                images={[
                                    { image: image_MicroFactors, long: true },
                                    { image: image_MacroFactors, long: true },
                                    { image: image_TemplateTable, long: true },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Google Sheets to Democratize Sales and Marketing Demo"
                                description="Design team had to often customize the content for Sales pitches and industry-specific marketing campaigns. It was an extremely time-consuming task and required content preparation. I eventually added a Figma Plugin to the process  which allows preparing the content on Google Sheets and applying it to Figma components in one click! It was a game-changer as it reduced the time spent on such redundant tasks from a few days to a few seconds!"
                                images={[{ image: image_GoogleSheets, long: true }]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Design Workshop Standardization"
                                description="I created design workshop agenda documents to standardize the workshops and the documentation by creating ready-to-use templates."
                                images={[
                                    { image: image_UserPersona, long: true },
                                    { image: image_UserJourneyMapping, long: true },
                                    { image: image_HealthCheck, long: true },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="One Line Strategy Statement"
                                description="For one of Squirro's early product, I conducted a One-Line Strategy Statement workshop to summarise the problem we are addressing, whom it will impact, and how we plan to solve it. The workshop revealed the vision misalignments within the management team and guided them to come up with the final strategy statement."
                                images={[{ image: image_ABApp, long: true }]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="UX KPIs"
                                description="Enterprise customers are sensitive about sharing data like email ids which makes qualitative analysis difficult. I came up with a strategy to collect qualitative data through the user interface by creating concepts of popup widgets and floating dashboard sections which can be trigerred into the view on satisfying certain conditions."
                                images={[{ image: image_PopupInsideFloatingSection, long: true }]}
                            />
                            <HalfWidthWrapper>
                                <ProblemStatement
                                    blue={true}
                                    title="Design is Not a Democracy"
                                    description="Design is visual, so it triggers emotions instantly. More emotions means more opinions, which is good, but it needs to be regulated. While some input might be reasonable, others might not and need to be rejected, leaving some team members unhappy. First, I separated serious feedback from casual by asking for written feedback. It immediately reduced the frequency and improved the quality of feedback. Second, I made team memebrs understand that the design team has authority over design decisions and thus it is also responsible for all outcomes, both good and bad. These decisions made our quality of life drastically better at work."
                                />
                                <ProblemStatement
                                    blue={true}
                                    title="Design Freeze"
                                    description="Design is never truly finished, but it needs to be paused for a proper handover, implementation, and testing. It is also important to use this pause to introspect on what has been done and prepare a vision for the next phase. Motivated to fix this issue along the lines of code freeze, I introduced the concept of the 'design freeze' stage in the customer project development."
                                />
                            </HalfWidthWrapper>
                        </>
                    }

                    {alignment === 'long' &&
                        <>
                            <ImageSubsection
                                blue={true}
                                title="Design Organisation Chart"
                                description="I made many decisions that impacted the functioning of the design department. I proposed a hybrid design team structure to the management to explain how designers work within different departments and still report back to the team. I also made a strategy to grow the team in the future, anticipating the new fields we might explore in design or as a company. I included design work packages in SoWs and thus made sure that the design efforts were paid well, just like any other team, and we had a good return on the design budget. For self-assessment, I placed ourselves on the standard design maturity scale from Forrester / NASDAQ to know where we stand and identify the gaps we need to fill."
                                images={[{ image: image_DesignOrg, long: true }]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="Design Rationale Documentation"
                                description="Even though the design decisions were well communicated to various stakeholders and team members, they would often forget what decisions were made and why. A significant proportion of the team's time was spent communicating the same decisions multiple times. To save time, I started an initiative to create guidelines and templates to document design decisions in a standardized and structured way.<p>I compiled commonly used macro and micro factors in the guidelines to defend decision designs and created dummy example sentences to set the tone. The template includes the date, participants, and decisions, along with some FAQs, so one can easily find the answers to their questions."
                                images={[
                                    { image: image_MicroFactors, long: true },
                                    { image: image_MacroFactors, long: true },
                                    { image: image_TemplateTable, long: true },
                                ]}
                            />
                            <ProblemStatement
                                title=""
                                description="This initiative was well received and became a standard process for all design projects. The team saved time and avoided going in circles."
                            />
                            <ProblemStatement
                                title="Demo Design Democratization"
                                description="The design team supports Sales and Marketing teams with assets for sales pitches to prospects or industry-specific marketing campaigns. We had to make a copy of our standard product designs on Figma and customize the content as requested. We often got requests like 'Can you make a copy of Cognitive Search designs with pharmaceutical industry related data?'."
                            />
                            <Solution
                                title="There were a few annoyances here:"
                                smallTitle={true}
                                description={[
                                    'It was an extremely time-consuming task.',
                                    'It was not the best utilization of the design team\'s time, as they were changing the text copy instead of doing the actual design work.',
                                    'Designers would not know the right content to tell a compelling story and would go back and forth with the person responsible for finalizing the copy.',
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="In light of reducing the recurring workload for design, I started looking for solutions where the responsibility of providing content lies with the person requesting the designs. After some effort, we found the <a href='https://www.figma.com/community/plugin/735770583268406934/google-sheets-sync' target='_blank' rel='noreferrer'>Google Sheets Figma Plugin</a>, which allows copying the content on Google Sheets to components on a Figma project in one click! We successfully tested the plugin and immediately decided to demonstrate its ease of use by giving a company-wide demo."
                                images={[{ image: image_GoogleSheets, long: true }]}
                            />
                            <ProblemStatement
                                title=""
                                description="It was a game-changer! The design team would prepare a spreadsheet for every standard product on Figma as per the new process. Teams requesting the content change would fill the spreadsheet. Once done, they would notify the design team to run the plugin and done! This process reduced the time spent on such redundant tasks from a few days to a few seconds!"
                            />
                            <ImageSubsection
                                blue={true}
                                title="Design Workshop Standardization "
                                description="Given the workload, the design team could not conduct design workshops and usability tests for every customer project. I decided to train the customer success team so they can do so when needed and share the workload with us. Therefore, I created workshop agenda documents to help them prepare for the workshop. I also standardized organizing design workshops and documenting the outcomes by creating ready-to-use templates."
                                images={[
                                    { image: image_UserPersona, long: true },
                                    { image: image_UserJourneyMapping, long: true },
                                    { image: image_HealthCheck, long: true },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description="The customer success team readily accepted my proposal and enthusiastically volunteered to learn from us. It resulted in a balanced workload sharing between the two teams, and a better understanding of user needs for the delivery engineers."
                                images={[
                                    { image: image_UsabilityTesting },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title=""
                                description=""
                                images={[
                                    { image: image_Screenshot2 },
                                    { image: image_Screenshot3 },
                                ]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="One Line Strategy Statement"
                                description="The product managers often gave the design team a set of requirements and issues to address through the designs. While focusing on the requirements, it was easy to lose sight of the underlying mission behind the product. I wanted us to think of the impact we wanted to make before we kicked off the designs. I conducted many design workshops for one of our early products, called the Augmented Banking App. One was an exercise to write a one-line strategy statement about the problem we are addressing, whom it will impact, and how we plan to solve it. I also shared the crisp and clear strategy statements from companies like Tesla.<p>The workshop revealed the vision misalignments among the management team members and helped them to agree on a final strategy statement to guide designs and development. Every participant was asked to write one, validate it, and challenge other members' statements. To my surprise, with just one line at its core, this workshop ran for hours and saw the most heated discussions. After this, I encouraged teams and customers to use this approach and confront internal disagreements and misalignments."
                                images={[{ image: image_ABApp, long: true }]}
                            />
                            <ImageSubsection
                                blue={true}
                                title="UX KPIs"
                                description="'What gets measured, gets managed.' Working at a data insights company, my pet peeve was not collecting enough qualitative data on user satisfaction and the ease of use of our products. It was partly due to the fact that enterprise customers are very sensitive about data security and do not want user emails to be accessible to the product team. Besides, their agreement for feedback collection is subject to approvals and compliances. As a first step towards measuring the Qualitative KPIs, namely, SUS (System Usability Scale), NPS (Net Promoter Score), and CSAT (Customer Satisfaction), I wanted to collect feedback through the user interface itself to avoid any data security and compliance issues. <p><a href='https://docs.squirro.com/en/latest/technical/dashboards/dashboard-editor.html?highlight=dashboard%20section#sections' target='_blank' rel='noreferrer'>Squirro dashboards are built using one or many sections</a> (center, top, bottom, left, right). I came up with the concept of the floating section to display widget popups to collect feedback. A floating section is a section whose content overlays on top of the other sections and thus on top of the entire dashboard. The widget can be configured to a type SUS, NPS, or CSAT."
                                images={[{ image: image_PopupInsideFloatingSection, long: true }]}
                            />
                            <Solution
                                title="I defined conditions for the popups to be displayed:"
                                smallTitle={true}
                                description={[
                                    'After the user finishes a task.',
                                    'After the user has had at least three sessions.',
                                    'After the user has been on a dashboard for at least two minutes.',
                                    'After the user has used a specific feature.',
                                    'After a product version release.',
                                ]}
                            />
                            <ProblemStatement
                                title="Design is Not a Democracy"
                                description="My most significant achievement at Squirro was successfully installing the mindset that design is not a democracy. Design is visual, so it triggers emotions instantly. More emotions means more opinions, which is good, but it needs to be regulated. Sharing designs with the team would start a stampede of feedback. While some input might be reasonable, others might not and need to be rejected, leaving some team members unhappy."
                            />
                            <Solution
                                title="We were in a soup:"
                                smallTitle={true}
                                description={[
                                    'Endless chats and discussions on trivial points were wasting hours.',
                                    'Strongly opinionated members would create their polls on chat channels, asking team members to vote. Some would also lobby behind the scenes to get their preferred designs implemented.',
                                ]}
                            />
                            <ProblemStatement
                                title=""
                                description="I needed to solve two problems here. First, to separate serious feedback from casual feedback. Second, to officially establish the design team's authority over design decisions. To solve the problem, I created an email address where team members not directly involved in the project could share feedback. It immediately reduced the frequency and improved the quality of feedback as they had to write down their thoughts and explain them with some clarity. To solve the latter, we communicated that authority goes hand in hand with responsibility. So, if the design team doesn't agree with a decision, it won't take responsibility for its outcome. These decisions made the design team's quality of life drastically better at work."
                            />
                            <ProblemStatement
                                title="Design Freeze"
                                description="Any UX designer knows what it feels like to be caught in a never-ending cycle of making design changes, especially in a customer project. When you think the design is almost done, there is always more feedback, often circumventing the details or topics outside the scope. Design is never truly finished, but it needs to be paused for a proper handover, implementation, and testing. It is also important to use this pause to introspect on what has been done and prepare a vision for the next phase. Motivated to fix this issue along the lines of code freeze, I introduced the concept of the 'design freeze' stage in the customer project development.<p>We soon had many Customer Project Managers onboard with this idea and we were able to push back on the never-ending changes by citing the Design Freeze without hesitation."
                            />
                        </>
                    }
                </div>
            </div>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
};

export default DesignManagement;
