
// props: name, fullName, logo

const CompanyLogo = (props) => {
    return (
        <div className="bubbleChild" title={props.data.fullName}>
            <img src={props.data.logo} className="logo-image" alt={props.data.name}/>
            {/* <div className="logo-fullName">
                {props.data.fullName}
            </div> */}
        </div>
    );
};

export default CompanyLogo;
