import './ImageSubsection.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';

// title, images, description, horizontal
const ImageSubsection = (props) => {
    const generateClassName = (image, baseClass = '') => {
        return `${baseClass} ${image.long ? 'long' : 'wide'} ${image.small ? 'small' : ''} ${image.verySmall ? 'verySmall' : ''}`;
    };

    return (
        <div className={`ImageSubsection ${props.smallTitle ? 'small' : ''} ${props.banner ? 'banner' : ''} ${props.noBackground ? 'noBackground' : ''} ${props.blue ? 'blue' : ''} ${props.noShadow ? 'noShadow' : ''} `}>
            {props.banner
                ?
                    <div className="bannerHeading">
                        <h2>
                            {props.title}
                        </h2>
                        <div className="description" dangerouslySetInnerHTML={{__html: props.description}}/>
                    </div>
                :
                    <>
                        <h2>
                            {props.title}
                        </h2>
                        <div className="description" dangerouslySetInnerHTML={{__html: props.description}}/>
                    </>
            }
            <div className={`images ${props.horizontal ? 'horizontal' : ''}`}>
                {props.images.map((image, ind) => {
                    return image.image
                        ?
                            <div className="image" key={ind}>
                                <Zoom>
                                    <img src={image.image} alt="" className={generateClassName(image)}/>
                                </Zoom>
                            </div>
                        :
                            <div className={generateClassName(image, 'video video-inner')} key={ind}>
                                <Player autoPlay muted loop>
                                    <source src={image.video} type="video/mp4"/>
                                </Player>
                            </div>
                })}
            </div>
        </div>
    );
};

export default ImageSubsection;
