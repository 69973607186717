import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import CompanyLogo from "./CompanyLogo";
import "./Companies.css";
import logo_ECB from '../../../images/companies/ECBlogo.svg';
import logo_helvetia from '../../../images/companies/helvetia.svg';
import logo_UBS from '../../../images/companies/UBS_Logo.svg';
import logo_synpulse from '../../../images/companies/synpulse-logo.svg';
import logo_wells from '../../../images/companies/wells.svg';
import logo_scb from '../../../images/companies/standard-chartered-logo.svg';
import logo_cs from '../../../images/companies/credit-suisse.svg';
import logo_henkel from '../../../images/companies/henkel.svg';
import logo_boe2 from '../../../images/companies/boe2.svg';
import logo_nzz2 from '../../../images/companies/nzz2.svg';
import logo_rbs from '../../../images/companies/rbs.svg';
import logo_refinitiv from '../../../images/companies/Refinitiv.svg';
import logo_argus from '../../../images/companies/argus.svg';
import logo_buhler from '../../../images/companies/buhler.svg';
import logo_ing from '../../../images/companies/ing.svg';
import logo_candriam from '../../../images/companies/candriam.svg';
import logo_salesforce from '../../../images/companies/Salesforce.svg';


export default function Companies(props) {
    const allData = [
        {
            fullName: 'Helvetia',
            logo: logo_helvetia,
        },
        {
            fullName: 'UBS',
            logo: logo_UBS,
        },
        {
            fullName: 'Salesforce',
            logo: logo_salesforce,
        },
        {
            fullName: 'European Central Bank',
            logo: logo_ECB,
        },
        {
            fullName: 'SCB',
            logo: logo_scb,
        },
        {
            fullName: 'Credit Suisse',
            logo: logo_cs,
        },
        {
            fullName: 'Henkel',
            logo: logo_henkel,
        },
        {
            fullName: 'Wells Fargo',
            logo: logo_wells,
        },
        {
            fullName: 'Bank of England',
            logo: logo_boe2,
        },
        {
            fullName: 'Royal Bank of Scotland',
            logo: logo_rbs,
        },
        {
            fullName: 'NZZ',
            logo: logo_nzz2,
        },
        {
            fullName: 'Refinitiv',
            logo: logo_refinitiv,
        },
        {
            fullName: 'Argus',
            logo: logo_argus,
        },
        {
            fullName: 'Buhler',
            logo: logo_buhler,
        },
        {
            fullName: 'ING',
            logo: logo_ing,
        },
        {
            fullName: 'Candriam',
            logo: logo_candriam,
        },
        {
            fullName: 'Synpulse',
            logo: logo_synpulse,
        },
    ];

	const options = {
		size: 128,
		minSize: 64,
		gutter: 8,
		provideProps: true,
		numCols: 4,
		fringeWidth: 160,
		yRadius: 130,
		xRadius: 220,
		cornerRadius: 50,
		showGuides: false,
		compact: true,
		gravitation: 5
	};

	const children = allData.map((data, i) => {
		 return <CompanyLogo data={data} key={i}/>
	});

	return (<BubbleUI options={options} className="myBubbleUI">
		{children}
	</BubbleUI>)
};
