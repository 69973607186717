import AboutMe from './pages/AboutMe';
import AboutProject from './pages/AboutProject';
import TopNav from './toolbar/TopNav';
import ScrollToTop from "react-scroll-to-top";
import aiStudioBanner from './images/ai_banner5.png';
import foodSafetyBanner from './images/foodsafety_banner2c.jpg';
import activityBanner from './images/activity_banner2c.jpg';
import designManagementBanner from './images/design_mgmt_banner.png';
import dashboardsBanner from './images/appbuilder_thumb3.png';
import riceAiBanner from './images/mgg-vitchakorn-zXNC_lBBVGE-unsplashCROPcc.jpg';
import Footer from './toolbar/Footer';
import { useWindowSize } from "@uidotdev/usehooks";


function Home() {
    const size = useWindowSize();

    const scrollToWork = () => {
        window.scrollTo({
            top: size.height - 60,
            behavior: 'smooth',
        });
    };

    // peach #FFBE98
    // darkerpeach #F4B695

    return (
        <div
            className="App"
            style={{height: size.height}}
        >
            <TopNav activeIndex={0}/>
            <AboutMe scrollToWork={scrollToWork}/>
            <AboutProject
                title="Design Management"
                description="Some of my initiatives in leadership roles"
                redirectUrl="mgmt"
                imageUrl={designManagementBanner}
                odd={true}/>
            <AboutProject
                title="AI Studio"
                description="A no-code / low-code Augmented Intelligence Studio to truly democratise building and training ML models"
                redirectUrl="aistudio"
                imageUrl={aiStudioBanner}
                odd={false}/>
            <AboutProject
                title="App Builder"
                description="An advanced drag&drop app building tool"
                redirectUrl="appbuilder"
                imageUrl={dashboardsBanner}
                odd={true}/>
            <AboutProject
                title="Activity Tracking"
                description="A user activity analytics product for enterprise solutions"
                redirectUrl="activity"
                imageUrl={activityBanner}
                odd={false}/>
            <AboutProject
                title="Food Safety"
                description="An app for the food industry to detect food hazards and mitigate risk"
                redirectUrl="foodsafety"
                imageUrl={foodSafetyBanner}
                odd={true}/>
            <AboutProject
                title="Rice AI"
                description="An app for rice processing plants to prevent wastage and yield high-quality rice"
                redirectUrl="riceai"
                imageUrl={riceAiBanner}
                odd={false}/>
            <Footer/>
            <ScrollToTop smooth />
        </div>
    );
}

export default Home;
